import * as React from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { AUTH_TOKEN, LICENSE_ACCEPTED } from '../constants';

// Context
import UserContext from '../context/UserContext';
import SubmenuContext from '../context/SubmenuContext';

// Pages
import Page from './Page/Page';
import GuidelinePage from './GuidelinePage/GuidelinePage';
import MediaPage from './MediaPage/MediaPage';

// Components
import Header from './Header/Header';
import Home from './Home';
import Login from './Login/Login';
import PrivateRoute from './PrivateRoute';
import Sidebar from './Sidebar/Sidebar';
import GridContainer from './GridContainer/GridContainer';
import Footer from './Footer/Footer';
import SSOConnector from './SSOConnector/SSOConnector';
import SearchResultsPage from './SearchResultsPage/SearchResultsPage';
import MediaPagePublic from './MediaPagePublic/MediaPagePublic';

const PROTECTED_QUERY = gql`
  query ProtectedQuery {
    viewer {
      userId
      username
      firstName
      lastName
      avatar {
        url
      }
    }
  }
`;

const App: React.FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = React.useState<any | null>();
  const [submenu, setSubmenu] = React.useState([]);
  const [burgerMenuOpen, setBurgerMenuOpen] = React.useState(false);

  const [checkAuth] = useLazyQuery(PROTECTED_QUERY, {
    onError: error => {
      // eslint-disable-next-line no-console
      console.error('Authentication Error');
      // eslint-disable-next-line no-console
      console.error(error);

      if (
        !location.pathname.includes('/login') &&
        !location.pathname.includes('/media-library/')
      )
        history.push('/login');
    },
    onCompleted: data => {
      if (data && data.viewer) {
        setUser({
          id: data.viewer.userId,
          username: data.viewer.username,
          firstName: data.viewer.firstName,
          lastName: data.viewer.lastName,
          avatar: data.viewer.avatar.url,
        });
      }
    },
  });

  const updateSubmenu = (submenuArray: any) => {
    setSubmenu(submenuArray);
  };

  const checkAuthentication = (authUser?: any) => {
    if (authUser) setUser({ ...authUser, avatar: authUser.avatar.url });
    checkAuth();
  };

  const logout = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(LICENSE_ACCEPTED);
    setUser({});
    history.push('/login');
  };

  const toggleBurgerMenu = () => {
    if (burgerMenuOpen) {
      document.body.removeAttribute('style');
    } else {
      document.body.style.overflowY = 'hidden';
    }
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  React.useEffect(() => {
    checkAuthentication();
  }, []);

  return (
    <UserContext.Provider value={user}>
      <main className="main-container">
        <Header logout={logout} toggleMenu={toggleBurgerMenu} />
        <GridContainer>
          <div className="row">
            <SubmenuContext.Provider value={{ submenu, updateSubmenu }}>
              <Sidebar
                menuOpen={burgerMenuOpen}
                toggleMenu={toggleBurgerMenu}
                logout={logout}
              />
              <Switch>
                <Route exact path="/login">
                  <Login authFunction={checkAuthentication} />
                </Route>
                <Route exact path="/login/sso/:token">
                  <SSOConnector authFunction={checkAuthentication} />
                </Route>
                <PrivateRoute exact path="/">
                  <Home />
                </PrivateRoute>
                <PrivateRoute exact path="/page/:slug">
                  <Page />
                </PrivateRoute>
                <PrivateRoute exact path="/guideline/:slug">
                  <GuidelinePage />
                </PrivateRoute>
                <PrivateRoute exact path="/guideline/:parentslug/:slug">
                  <GuidelinePage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path={[
                    '/media/:mediaTypeId/',
                    '/media/:parentMediaTypeId/media/:mediaTypeId',
                    '/page/:slug/media/:mediaTypeId/',
                    '/page/:slug/media/:parentMediaTypeId/:mediaTypeId/',
                  ]}
                >
                  <MediaPage />
                </PrivateRoute>
                <PrivateRoute exact path="/search/:searchTerm">
                  <SearchResultsPage />
                </PrivateRoute>
                <Route exact path="/media-library/:slug">
                  <MediaPagePublic />
                </Route>
              </Switch>
            </SubmenuContext.Provider>
          </div>
        </GridContainer>
      </main>
      <Footer />
    </UserContext.Provider>
  );
};

export default App;
