import * as React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

// Components
import LayoutContainer from '../LayoutContainer/LayoutContainer';

// Types
import { SearchResultData, SearchResultNode } from '../../types/Search.types';

// Styles
import './SearchResultsPage.scss';
import SearchResults from '../SearchResults/SearchResults';
import Loader from '../Loader/Loader';

const SEARCH_QUERY = gql`
  query SearchQuery($searchTerm: String!) {
    contentNodes(
      where: {
        search: $searchTerm
        status: PUBLISH
        parent: "null"
        contentTypes: GUIDELINES
      }
    ) {
      nodes {
        __typename
        id
        link
        ... on Guideline {
          title
          content
        }
        status
        slug
      }
    }
  }
`;

const SearchResultsPage: React.FC = () => {
  // TODO: Add Pagination!
  const { searchTerm } = useParams();
  const [searchResults, setSearchResults] = React.useState<SearchResultNode[]>(
    [],
  );

  const handleComplete = (data: SearchResultData) => {
    const { nodes: resultData } = data.contentNodes;
    setSearchResults(resultData);
  };

  const { loading } = useQuery(SEARCH_QUERY, {
    variables: { searchTerm },
    onCompleted: handleComplete,
  });

  return (
    <LayoutContainer>
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <div className="search-results-page">
              <h1>Search Results</h1>
              <hr />
              <p className="search-results-page__term">
                For: <span>{searchTerm}</span>
              </p>
              {loading && <Loader />}
              {!loading && searchResults && (
                <SearchResults
                  searchTerm={searchTerm}
                  results={searchResults}
                />
              )}
              {!loading && searchResults.length === 0 && (
                <div className="search-results-page__no-results">
                  <h3>
                    No results for your query <span>"{searchTerm}"</span>
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default SearchResultsPage;
