import * as React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import captureLinkClicks from '../utils/CaptureLinkClicks';

// Components
import Loader from '../components/Loader/Loader';
import LayoutContainer from './LayoutContainer/LayoutContainer';

const HOME_QUERY = gql`
  query PageQuery {
    userHomepage {
      nodes {
        title
        content_mapped
      }
    }
  }
`;

const Home: React.FunctionComponent = () => {
  const [homepage, setHomepage] = React.useState();
  const { data, loading } = useQuery(HOME_QUERY);
  const history = useHistory();

  React.useEffect(() => {
    if (data) {
      let homepageData = data.userHomepage.nodes[0];

      if (!homepageData) {
        homepageData = {
          content_mapped: '<p>Please set a Homepage in the Backend Area</p>',
        };
      }

      setHomepage(homepageData);
    }
  }, [data]);

  return (
    <LayoutContainer>
      {loading && <Loader />}
      {homepage && (
        <div
          className="home"
          dangerouslySetInnerHTML={{ __html: homepage.content_mapped }}
          onClick={(event: React.MouseEvent<HTMLElement>) =>
            captureLinkClicks(event, history)
          }
        />
      )}
    </LayoutContainer>
  );
};

export default Home;
