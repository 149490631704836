import * as React from 'react';
import classNames from 'classnames';
import Tippy from '@tippy.js/react';

// Context
import UserContext from '../../context/UserContext';

// Components
import UserDropdown from '../UserDropdown/UserDropdown';
import IconCaretDown from '../../icons/IconCaretDown';

// Styles
import 'tippy.js/dist/tippy.css';
import './UserAvatar.scss';

interface UserAvatar {
  logout: () => void;
  alignment?: 'left' | 'center' | 'right';
  prependUserImage?: boolean;
  caretVisible?: boolean;
}

const UserAvatar: React.FunctionComponent<UserAvatar> = ({
  logout,
  alignment = 'center',
  prependUserImage = false,
  caretVisible = true,
}: UserAvatar) => {
  return (
    <UserContext.Consumer>
      {user => (
        <div
          className={classNames('user-avatar', {
            [`--align-${alignment}`]: true,
            '--prepend': prependUserImage,
          })}
        >
          {user && (
            <Tippy
              interactive={true}
              zIndex={99}
              placement="bottom-end"
              content={<UserDropdown logout={logout} />}
            >
              <div className="user-avatar__trigger">
                {prependUserImage && (
                  <div
                    className="user-avatar__avatar"
                    style={{ backgroundImage: `url(${user.avatar})` }}
                  />
                )}
                <p className="user-avatar__name">
                  {`${user.firstName} ${user.lastName}`}
                </p>
                {caretVisible && (
                  <div className="user-avatar__caret">
                    <IconCaretDown />
                  </div>
                )}
                {!prependUserImage && (
                  <div
                    className="user-avatar__avatar"
                    style={{ backgroundImage: `url(${user.avatar})` }}
                  />
                )}
              </div>
            </Tippy>
          )}
        </div>
      )}
    </UserContext.Consumer>
  );
};

export default UserAvatar;
