/* eslint-disable import/no-extraneous-dependencies */
import * as React from 'react';
import uuid from 'uuid/v1';
import Tippy from '@tippy.js/react';

// Styles
import './DownloadButton.scss';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';

// Types
import { IUser } from '../../context/UserContext';
import { MediaItemFile } from '../../types/MediaItems.types';

interface ISource {
  displayName: string;
  download: boolean;
  externalUrl?: string;
  src?: MediaItemFile;
}
interface ISourceList {
  sources: ISource[];
}

interface IDownloadButton {
  children?: React.ReactFragment;
  sources: ISource[];
  sensibleButton?: boolean;
  user?: IUser;
}

const SourceList: React.FunctionComponent<ISourceList> = (
  props: ISourceList,
) => {
  const { sources } = props;

  return (
    <div className="source-list">
      {sources.map(source => (
        <a
          key={uuid()}
          href={source.src?.guid || source.externalUrl}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          {source.displayName}
        </a>
      ))}
    </div>
  );
};

const DownloadButton: React.FunctionComponent<IDownloadButton> = (
  props: IDownloadButton,
) => {
  const { children, sources, sensibleButton = false, user = undefined } = props;

  const filteredSources = sources.filter(
    source => source.download || user || source.externalUrl,
  );

  if (sensibleButton && filteredSources.length === 0 && user === undefined) {
    return <div />;
  }

  return (
    <div className="download-button">
      <Tippy
        interactive
        placement="bottom-end"
        arrow={false}
        animation="shift-away"
        distance="-2"
        content={<SourceList sources={filteredSources} />}
      >
        <div className="download-button__wrapper">
          <span className="download-button__button">{children}</span>
        </div>
      </Tippy>
    </div>
  );
};

export default DownloadButton;
