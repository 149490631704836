import * as React from 'react';
import ReactDOM from 'react-dom';
import { gql, useQuery } from '@apollo/client';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import captureLinkClicks from '../../utils/CaptureLinkClicks';

// Components
import LayoutContainer from '../LayoutContainer/LayoutContainer';
import Loader from '../Loader/Loader';
import ImageComparison from '../ImageComparison/ImageComparison';
import ImageLightbox from '../ImageLightbox/ImageLightbox';

// Context
import SubmenuContext from '../../context/SubmenuContext';

// Styles
import './GuidelinePage.scss';

/**
 * GraphQL guideline query that takes a guideline slug as a uri
 * Returns the title and content of the guideline
 */
const PAGE_QUERY = gql`
  query PageQuery($uri: String!) {
    guidelineBy(uri: $uri) {
      title
      content_mapped
      sidebarLinks {
        sections {
          label
          sectionId
        }
      }
    }
  }
`;

interface Guideline {
  title: string;
  content_mapped: string;
  sidebarLinks: {
    sections: {
      label: string;
      sectionId: string;
      __typename: string;
    }[];
  };
}

const GuidelinePage: React.FunctionComponent = () => {
  const [guideline, setGuideline] = React.useState<Guideline | null>();
  const { updateSubmenu } = React.useContext(SubmenuContext);
  const { slug, parentslug }: any = useParams();
  const location = useLocation();
  const history = useHistory();

  const combinedSlug = parentslug ? `${parentslug}/${slug}` : slug;

  const handleComplete = (data: any) => {
    if (data) {
      if (!data.guidelineBy) {
        alert(
          'Not authorized to access this page. You will be redirected to Home.',
        );
        history.push('/');
        return false;
      }

      const { title, content_mapped, sidebarLinks } = data.guidelineBy;

      if (sidebarLinks.sections) {
        updateSubmenu(sidebarLinks.sections);
      }

      setGuideline({
        title,
        content_mapped,
        sidebarLinks,
      });
    }
  };

  const { loading } = useQuery(PAGE_QUERY, {
    variables: { uri: combinedSlug },
    onCompleted: handleComplete,
  });

  React.useEffect(() => {
    // TODO: Refactor to custom Hook!
    if (guideline && guideline.content_mapped) {
      const imageComparisonElements: NodeListOf<HTMLElement> = document.querySelectorAll(
        '.sonnen-image-comparison',
      );

      imageComparisonElements.forEach((element, index) => {
        const imageMain = element.dataset.imageMain;
        const imageComparison = element.dataset.imageComparison;
        const imageWidth = element.dataset.imageWidth;
        const imageHeight = element.dataset.imageHeight;

        ReactDOM.render(
          <ImageComparison
            main={imageMain}
            comparison={imageComparison}
            width={imageWidth}
            height={imageHeight}
          />,
          document.querySelectorAll('.sonnen-image-comparison')[index],
        );
      });

      const imageLightboxElements: NodeListOf<HTMLElement> = document.querySelectorAll(
        '.sonnen-image-lightbox',
      );

      imageLightboxElements.forEach((element, index) => {
        const {
          imagePreview,
          imageLightbox,
          previewAlt,
          lightboxAlt,
          isPortrait,
        } = element.dataset;

        ReactDOM.render(
          <ImageLightbox
            preview={imagePreview}
            lightbox={imageLightbox}
            previewAlt={previewAlt}
            lightboxAlt={lightboxAlt}
            isPortrait={isPortrait}
          />,
          document.querySelectorAll('.sonnen-image-lightbox')[index],
        );
      });
    }

    // Scroll to Hash, if present (Only necessary after Reload, thus on Guideline Change)
    const { hash } = location;

    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        setTimeout(
          () => element.scrollIntoView({ block: 'start', behavior: 'smooth' }),
          300,
        );
      }
    }
  }, [guideline, location]);

  return (
    <LayoutContainer>
      <div className="guideline">
        {loading && <Loader />}
        {guideline && !loading && (
          <>
            <div className="guideline__title">
              <h1>{guideline.title}</h1>
            </div>
            <div className="page__content">
              <div
                dangerouslySetInnerHTML={{ __html: guideline.content_mapped }}
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  captureLinkClicks(event, history)
                }
              />
            </div>
          </>
        )}
      </div>
    </LayoutContainer>
  );
};

export default GuidelinePage;
