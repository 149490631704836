import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import uuid from 'uuid/v1';
import { useLazyQuery, gql } from '@apollo/client';
import classNames from 'classnames';

// Types
import { ISidebar } from '../../types/Sidebar.types';

// Components
import IconClose from '../../icons/IconClose';
import SonnenLogo from '../../static/images/logo-sonnen-white.svg';
import UserAvatar from '../UserAvatar/UserAvatar';

// Context
import SubmenuContext from '../../context/SubmenuContext';
import UserContext from '../../context/UserContext';

// Styles
import './Sidebar.scss';
import SidebarMediaLibrary from './SidebarMediaLibrary';

const MENU_QUERY = gql`
  query MenuQuery {
    userMenu {
      nodes {
        name
        menuOptions {
          menuType
          menuTitle
        }
        menuItems(first: 999) {
          nodes {
            label
            path
            parentId
            connectedNode {
              node {
                ... on MediaType {
                  id
                  slug
                  name
                }

                ... on Page {
                  id
                  slug
                  title(format: RAW)
                }
              }
            }
            childItems(first: 999) {
              nodes {
                label
                path
                databaseId
                parentId
                connectedNode {
                  node {
                    ... on MediaType {
                      id
                    }
                  }
                }
                childItems(first: 999) {
                  nodes {
                    label
                    path
                    databaseId
                    parentId
                    connectedNode {
                      node {
                        ... on MediaType {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Sidebar: React.FunctionComponent<ISidebar> = ({
  menuOpen,
  toggleMenu,
  logout,
}: ISidebar) => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = React.useState(location);
  const user = React.useContext(UserContext);
  const { submenu, updateSubmenu } = React.useContext(SubmenuContext);
  const [mainMenu, setMainMenu] = React.useState<any | null>(null);
  const [secMenu, setSecMenu] = React.useState<any | null>(null);

  function filterMenuItemsByParentId(menuItems: []) {
    return menuItems.filter((node: any) => node.parentId === null);
  }

  function constructPathName(path: string) {
    /**
     * Prefix Paths to Pages with /page
     */
    if (path.indexOf('guideline') < 0) {
      path = `/page${path}`;
    }

    return path;
  }

  const [fetchMenuData, { loading, data }] = useLazyQuery(MENU_QUERY, {
    onCompleted: function(menuData) {
      const menuMainData = menuData.userMenu.nodes.filter(
        (node: any) => node.menuOptions.menuType === 'guidelines',
      )[0];

      const menuSecondaryData = menuData.userMenu.nodes.filter(
        (node: any) => node.menuOptions.menuType === 'media',
      )[0];

      if (menuMainData) {
        const menuMain = {
          ...menuMainData,
          menuItems: filterMenuItemsByParentId(menuMainData.menuItems.nodes),
        };

        setMainMenu(menuMain);
      }

      if (menuSecondaryData) {
        const menuSecondary = {
          ...menuSecondaryData,
          menuItems: filterMenuItemsByParentId(
            menuSecondaryData.menuItems.nodes,
          ),
        };

        setSecMenu(menuSecondary);
      }
    },
  });

  const handleLinkClick = () => {
    if (menuOpen) toggleMenu();
  };

  React.useEffect(() => {
    if (user) fetchMenuData();
  }, [user, fetchMenuData]);

  React.useEffect(() => {
    // Set Submenut-Context to Empty Array when Changing Routes
    if (prevLocation.pathname !== location.pathname) {
      updateSubmenu([]);
    }
    setPrevLocation(location);
  }, [prevLocation, location, updateSubmenu]);

  return (
    <div
      className={classNames('col-sm-2 sidebar', {
        '--open': menuOpen,
      })}
    >
      <div className="sidebar__inner">
        <div className="sidebar__logo">
          <img src={SonnenLogo} alt="Sonnen | Brandportal" />
          <div onClick={toggleMenu}>
            <IconClose />
          </div>
        </div>
        {!loading && data && (
          <>
            {mainMenu && (
              <>
                <p className="sidebar__title">
                  {mainMenu.menuOptions.menuTitle}
                </p>
                <ul className="nav-menu">
                  {mainMenu.menuItems.map((menu: any) => (
                    <li key={uuid()}>
                      <NavLink to={constructPathName(menu.path)}>
                        {menu.label}
                      </NavLink>
                      {menu.childItems.nodes.length > 0 && (
                        <ul className="submenu">
                          {menu.childItems.nodes.map((submenu1: any) => {
                            return (
                              <li key={uuid()}>
                                <NavLink
                                  to={constructPathName(submenu1.path)}
                                  activeClassName="active"
                                  onClick={handleLinkClick}
                                >
                                  {submenu1.label}
                                </NavLink>
                                {location.pathname === submenu1.path && (
                                  <ul className="submenu--2">
                                    {submenu.map(submenu2 => (
                                      <li key={uuid()}>
                                        <NavHashLink
                                          smooth
                                          to={`#${submenu2.sectionId.trim()}`}
                                          activeClassName="active"
                                          isActive={(match, location) => {
                                            if (
                                              `#${submenu2.sectionId.trim()}` ===
                                              location.hash
                                            )
                                              return true;
                                            return false;
                                          }}
                                          onClick={handleLinkClick}
                                        >
                                          {submenu2.label}
                                        </NavHashLink>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </>
            )}
            {secMenu && (
              <>
                <p className="sidebar__title">
                  {secMenu.menuOptions.menuTitle}
                </p>
                <ul className="nav-menu">
                  <SidebarMediaLibrary
                    menuOpen={menuOpen}
                    toggleMenu={toggleMenu}
                    logout={logout}
                    data={secMenu}
                  />
                </ul>
              </>
            )}
            <div className="sidebar__avatar">
              <UserAvatar
                logout={logout}
                alignment="left"
                prependUserImage={true}
                caretVisible={false}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
