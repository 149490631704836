import * as React from 'react';

interface ILayoutContainer {
  children: React.ReactNode;
}

const LayoutContainer: React.FunctionComponent<ILayoutContainer> = (
  props: ILayoutContainer,
) => {
  const { children } = props;
  return <div className="col-sm-10">{children}</div>;
};

export default LayoutContainer;
